<template>
  <div class="form-group" v-if="players.length > 0">
    <label>{{ $t('choose') }} {{ label }}</label>
    <div class="d-block" v-for="(player, i) in players" :key="`${name}-${i}`">
      <div class="d-inline-block mb-2" :class="type === 'single' ? 'custom-control custom-radio' : 'custom-control custom-checkbox'">
        <input class="custom-control-input" :type="type === 'single' ? 'radio' : 'checkbox'" :name="`${name}`" :value="`${player.id}-${player.team_id}`" :id="`gk${player.id}`">
        <label class="custom-control-label" :for="`gk${player.id}`">
          <strong>{{ player.nick }}</strong>
          <small> | {{ $tc('point', 2) | toCapitalize }}: {{ player.punctuation }}  | {{ $t('assistances') }}: {{ player.assists }} | {{ $tc('goal', 2) | toCapitalize }}: {{ player.goals }}  | {{ $t('bestPlayer') }}: {{ player.bests }}</small>
        </label>
      </div>
      <div class="custom-control custom-checkbox d-inline-block ml-3">
        <input type="checkbox" class="custom-control-input" name="alternate" :value="`${player.id}-${player.team_id}`" :id="`alt_${name}${player.id}`">
        <label :for="`alt_${name}${player.id}`" class="custom-control-label">
          <strong><small>{{ $t('alternate') }}</small></strong>
        </label>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    name: {
      required: true,
      type: String
    },
    label: {
      required: true,
      type: String
    },
    type: {
      required: true,
      type: String
    },
    players: {
      required: true,
      type: Array
    }
  }
}
</script>
